import Layout from "@/components/Layout";
import { SSRProvider } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./../styles/globals.css";
import "../public/assests/css/styles.css";
import { wrapper } from "@/redux/store"; //wrapper for redux api call

// import Splitting from "splitting";
import { useEffect } from "react";

import "../styles/main.css";
import "../styles/water_affairs_scss.css";
import Aos from "aos";
import { useRouter } from "next/router";

function App({ Component, pageProps }) {
  useEffect(() => {
    Aos.init();
    import("../node_modules/bootstrap/dist/js/bootstrap.bundle");
    // Check if the current URL does not start with "www."
    if (!window.location.hostname.startsWith("www.")) {
      // Redirect to the www version of the URL while preserving the query string
      const newUrl = `https://www.${window.location.hostname}${window.location.pathname}${window.location.search}`;
      window.location.href = newUrl;
    }
  }, []);
  const router = useRouter();
  if (router.pathname === "/green-habitants/water-affair")
    return <Component {...pageProps} />;
  return (
    <SSRProvider>
      <Layout>
        <Component {...pageProps} />
      </Layout>
    </SSRProvider>
  );
}
export default wrapper.withRedux(App);
